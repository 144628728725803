.namizje-content-wrap {
    //margin-right: 250px;
    display:flex;
    flex-direction:column;
    height:100vh;

    @include media-breakpoint-up(md) {
        margin-right: 250px;
    }
}

// Profil sidebar - desktop
.profil-sidebar {
    display: none;

    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 250px;
        top: 0;
        bottom: 0;
        right: 0;
        //background-color: #f2eef1;
        background-color: #eaeff7;
        padding: 25px;
        border-radius: 25px 0 0 25px;
        height: 100%;
        overflow-y: auto;
    }

    &.profil-sidebar-static {
        position: static;
        border-radius: 25px;

        .user-info-wrap:last-child {
            border-bottom: 0;
            padding-bottom: 10px;
        }
    }

    .intro-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;

        .profil-avatar {
            height: 95px;
            width: 95px;
            background-color: $blue;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            color: #fff;
            text-transform: uppercase;
            flex-shrink: 0;
            margin: 25px 0 25px 0;
        }

        .profil-name {
            font-size: 21px;
            text-align: center;
        }
    }

    .user-info-wrap {
        margin-bottom: 0;
        padding: 40px 0 30px 0;
        border-bottom: 1px solid #dcd9dd;

        .label {
            color: #9e9ea0;
        }

        .data {
            margin-bottom: 10px;
        }
    }

    .user-notification {
        margin-top: 40px;

        ul {
            list-style: none;
            padding-left: 0;
            margin: 10px 0 10px 0;

            li {
                padding-left: 1em;
                text-indent: -1em;

                &:before {
                    content: "";
                    display: inline-block;
                    width: 9px;
                    height: 9px;
                    margin-right: 6px;
                    vertical-align: middle;
                    background: url('../img/icon-red-dot.svg') no-repeat;
                }
            }

            li.active {
                &:before {
                    //background: url('../img/icon-green-dot.svg') no-repeat;
                    background: url('../img/icon-dark-dot.svg') no-repeat;
                }
            }
        }
    }

    .user-links {
        margin-top: auto;

        .edit {
            margin-bottom: 10px;

            a:after {
                content: "";
                display: inline-block;
                width: 24px;
                height: 24px;
                vertical-align: middle;
                margin-left: 7px;
                background: url('../img/icon-arrow-right.svg') no-repeat;
            }

            a:hover:after {
                margin-left: 12px;
            }
        }

        .logout {
            a:after {
                content: "";
                display: inline-block;
                width: 20px;
                height: 18px;
                vertical-align: middle;
                margin-left: 7px;
                background: url('../img/icon-logout.svg') no-repeat;
            }

            a:hover:after {
                margin-left: 12px;
            }
        }
    }
}

// Profil sidebar - mobile
.profil-sidebar-mobile {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #f2eef1;
    padding: 15px;
    overflow: auto;

    .intro-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;

        .profil-avatar {
            height: 95px;
            width: 95px;
            background-color: $blue;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            color: #fff;
            text-transform: uppercase;
            flex-shrink: 0;
            margin: 5px 0 10px 0;
        }

        .profil-name {
            font-size: 21px;
            text-align: center;
        }
    }

    .user-info-wrap {
        margin-bottom: 0;
        padding: 15px 0 5px 0;
        text-align: center;
        //border-bottom: 1px solid #dcd9dd;

        .label {
            color: #9e9ea0;
        }

        .data {
            margin-bottom: 10px;
        }
    }

    .user-info-wrap.b-border {
        border-bottom: 1px solid #dcd9dd;
    }

    .user-notification {
        display: none;
        margin-top: 40px;
        text-align: center;

        ul {
            list-style: none;
            padding-left: 0;
            margin: 10px 0 10px 0;

            li {
                padding-left: 1em;
                text-indent: -1em;

                &:before {
                    content: "";
                    display: inline-block;
                    width: 9px;
                    height: 9px;
                    margin-right: 6px;
                    vertical-align: middle;
                    background: url('../img/icon-green-dot.svg') no-repeat;
                }
            }
        }
    }

    .user-links {
        margin-top: auto;
        text-align: center;

        .edit {
            margin-bottom: 10px;

            a:after {
                content: "";
                display: inline-block;
                width: 24px;
                height: 24px;
                vertical-align: middle;
                margin-left: 7px;
                background: url('../img/icon-arrow-right.svg') no-repeat;
            }
        }

        .logout {
            a:after {
                content: "";
                display: inline-block;
                width: 20px;
                height: 18px;
                vertical-align: middle;
                margin-left: 7px;
                background: url('../img/icon-logout.svg') no-repeat;
            }
        }
    }

    .mobile-profil-close {
        display: block;
        width: 20px;
        height: 20px;
        min-height: 20px;
        background: url('../img/icon-mobile-menu-close.svg');
        opacity: 1;
        padding: 0;
        border-radius: 0;
    }
}
.offcanvas.offcanvas-top {
    height:100vh;
}

// Content
p {
    .red {
        color:$red;
    }

    .blue {
        color:$blue;
    }
}

.heading-welcome {
    margin-top:45px;
    h1 {
        font-size:30px;
        line-height:42px;
        font-weight:400;
    }
    .orange {
        color:$orange;
    }
}

ul.green-dots {
    list-style: none;
    padding-left: 0;
    margin: 10px 0 10px 0;

    li {
        padding-left: 25px;
        text-indent: -25px;

        &:before {
            content: "";
            display: inline-block;
            width: 9px;
            height: 9px;
            margin-right: 16px;
            //vertical-align: middle;
            background: url('../img/icon-green-dot.svg') no-repeat;
        }
    }
}

ul.black-dots {
    list-style: none;
    padding-left: 0;
    margin: 10px 0 10px 0;

    li {
        padding-left: 25px;
        text-indent: -25px;

        &:before {
            content: "";
            display: inline-block;
            width: 9px;
            height: 9px;
            margin-right: 16px;
            //vertical-align: middle;
            background: url('../img/icon-dark-dot.svg') no-repeat;
        }
    }
}

.bold{
    font-weight:bold !important
}

.controls .add:after { 
    content: "";
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin-left: 7px;
    background: url(../img/icon-add.svg) no-repeat;
}

div .clickable {
    cursor: pointer;
}