@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay/RedHatDisplay-ExtraBold.woff2') format('woff2'), url('../fonts/RedHatDisplay/RedHatDisplay-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay/RedHatDisplay-ExtraBoldItalic.woff2') format('woff2'), url('../fonts/RedHatDisplay/RedHatDisplay-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay/RedHatDisplay-MediumItalic.woff2') format('woff2'), url('../fonts/RedHatDisplay/RedHatDisplay-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay/RedHatDisplay-Bold.woff2') format('woff2'), url('../fonts/RedHatDisplay/RedHatDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay/RedHatDisplay-Light.woff2') format('woff2'), url('../fonts/RedHatDisplay/RedHatDisplay-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay/RedHatDisplay-BoldItalic.woff2') format('woff2'), url('../fonts/RedHatDisplay/RedHatDisplay-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay/RedHatDisplay-Regular.woff2') format('woff2'), url('../fonts/RedHatDisplay/RedHatDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay/RedHatDisplay-SemiBold.woff2') format('woff2'), url('../fonts/RedHatDisplay/RedHatDisplay-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay/RedHatDisplay-Italic.woff2') format('woff2'), url('../fonts/RedHatDisplay/RedHatDisplay-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay/RedHatDisplay-SemiBoldItalic.woff2') format('woff2'), url('../fonts/RedHatDisplay/RedHatDisplay-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay/RedHatDisplay-Black.woff2') format('woff2'), url('../fonts/RedHatDisplay/RedHatDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay/RedHatDisplay-Medium.woff2') format('woff2'), url('../fonts/RedHatDisplay/RedHatDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay/RedHatDisplay-BlackItalic.woff2') format('woff2'), url('../fonts/RedHatDisplay/RedHatDisplay-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay/RedHatDisplay-LightItalic.woff2') format('woff2'), url('../fonts/RedHatDisplay/RedHatDisplay-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}