.software-wrap {
    display: flex;

    .sw-logo {
        flex-shrink: 0;
        margin-right: 10px;
    }

    .sw-content {
        .sw-title {
            font-size: 18px;
            margin: 10px 0 10px 0;
        }

        .sw-version {
            font-size: 16px;
            list-style: none;
            padding: 0;
            margin: 0 0 5px 0;

            li {
                display: inline-block;
                padding: 0 5px 0 8px;
                margin: 2px 0 2px 0;
                border-left: 1px solid #E8E8E9;

                &:first-child {
                    border-left: none;
                    padding-left: 0;
                }
            }
        }

        .sw-file {
            font-size: 13px;
            margin-bottom:3px;
        }

        
    }
}

.sw-file-download {
    font-size: 13px;

    a:before {
        content: "";
        display: inline-block;
        width: 12px;
        height: 14px;
        vertical-align: text-top;
        margin-right: 7px;
        background: url('../img/icon-sf-download.svg') no-repeat;
    }
}
