.editor-wrap {

    .ql-toolbar.ql-snow {
        border: 3px solid $blue;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background-color: #F2EEF1;
    }

    .ql-container.ql-snow {
        border: 3px solid $blue;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background-color: #fff;
    }

    .ql-editor {
        min-height: 500px;
    }

    &.ql-editor-small .ql-editor {
        min-height: 150px;
    }
}

.hidden-input-text {
    width: 0;
    height: 0;
    border: 0;

    &:focus {
        outline: 0;
    }
}
