/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack

// Configuration

@import "../../../skupno/src/scss/_custom-variables.scss";
@import "bootstrap/scss/bootstrap";
@import "../../../skupno/src/scss/_variables.scss";
@import "notyf/notyf.min";
@import "select2/dist/css/select2.min";


$bootstrap-icons-font-src: url("../fonts/bootstrap-icons/bootstrap-icons.woff2?524846017b983fc8ded9325d94ed40f3") format("woff2"), url("../fonts/bootstrap-icons/bootstrap-icons.woff?524846017b983fc8ded9325d94ed40f3") format("woff") !default;
@import "~bootstrap-icons/font/bootstrap-icons.scss";

@import "../../../skupno/src/scss/_fonts.scss";

//@import "layout-test";

@import "layout";
@import "tiles";
@import "tables";
@import "namizje";
@import "internet";
@import "accordion";
@import "popover";
@import "login";
@import "daterangepicker";
@import "modal";
@import "quill";
@import "quillsnow";
@import "editor";
@import "buttons";
@import "fullscreen-loader";
@import "select2";