// Popovers
.popover {
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    .popover-body {
        padding: 20px 25px 20px 25px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin: 5px 0 5px 0;
            }

            .edit:before {
                content: "";
                display: inline-block;
                width: 14px;
                height: 14px;
                margin-right: 7px;
                vertical-align: text-top;
                background: url('../img/icon-popover-edit.svg') no-repeat;
            }

            .deactivate:before {
                content: "";
                display: inline-block;
                width: 15px;
                height: 15px;
                margin-right: 7px;
                vertical-align: text-top;
                background: url('../img/icon-popover-deactivate.svg') no-repeat;
            }

            .activate:before {
                content: "";
                display: inline-block;
                width: 16px;
                height: 14px;
                margin-right: 7px;
                vertical-align: text-top;
                background: url('../img/icon-popover-activate.svg') no-repeat;
            }

            .delete {
                color: $red;

                &:before {
                    content: "";
                    display: inline-block;
                    width: 14px;
                    height: 16px;
                    margin-right: 7px;
                    vertical-align: text-top;
                    background: url('../img/icon-popover-delete.svg') no-repeat;
                }
            }

            .logs:before {
                content: "";
                display: inline-block;
                width: 14px;
                height: 16px;
                margin-right: 7px;
                vertical-align: text-top;
                background: url('../img/icon-popover-logs.svg') no-repeat;
            }
        }
    }
}