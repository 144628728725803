body {
    padding-top: 55px;
    background-color: $background-color;
    //overflow-x: hidden;

    @include media-breakpoint-up(md) {
        margin-left: 250px;
        padding-top: 0;
    }
}

// Navigacija
.navbar {
    border-radius: 0 0 25px 25px;
    //background: rgb(218,234,199);
    background: rgb(251, 231, 228);
    //background: linear-gradient(90deg, rgba(218,234,199,1) 50%, rgba(187,207,224,1) 100%);
    background: linear-gradient(90deg, rgba(251, 231, 228, 1) 50%, rgba(229, 234, 245, 1) 100%);
    padding: 0;

    @include media-breakpoint-up(md) {
        border-radius: 0 25px 25px 0;
        //background: rgb(218,234,199);
        background: rgb(251, 231, 228);
        //background: linear-gradient(180deg, rgba(218,234,199,1) 50%, rgba(187,207,224,1) 100%);
        background: linear-gradient(180deg, rgba(251, 231, 228, 1) 50%, rgba(229, 234, 245, 1) 100%);
        padding: 17px 0 0 0;
    }

    .navbar-toggler {
        padding-left: 20px;

        .navbar-toggler-icon {
            background-image: url('../img/icon-mobile-menu.svg');
        }

        &:focus {
            box-shadow: none;
        }
    }

    .navbar-logo {
        margin: 0 auto;

        img {
            max-height: 55px;

            @include media-breakpoint-up(md) {
                max-height: none;
            }
        }
    }

    .login-welcome {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
            font-size: 16px;
            margin: 0 auto;
            margin-top: 13px;
            padding: 0 20px 0 20px;
        }
    }

    .profil-avatar {
        height: 40px;
        width: 40px;
        background-color: $blue;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-transform: uppercase;
        flex-shrink: 0;
        margin-right: 15px;

        a {
            color: #fff;
            text-decoration: none;
        }

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}


.navbar.fixed-left {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;

    @include media-breakpoint-up(md) {
        right: auto;
        bottom: 0;
        width: 250px;
        flex-flow: column nowrap;
        align-items: flex-start;
        height: 100%;
        overflow-y: auto;
    }

    .navbar-offcanvas {
        padding: 20px 0px 20px 20px;
        border-radius: 0px 25px 25px 0px;
        background: rgb(222, 228, 241);
        background: linear-gradient(230deg, rgba(222, 228, 241, 1) 20%, rgba(250, 225, 222, 1) 60%);
        height: 100%;
        overflow-y: auto;

        @include media-breakpoint-up(md) {
            padding: 0px 0px 25px 25px;
            flex-grow: 1;
            flex-direction: column;
            width: 100% !important;
            background: none;
        }


        .mobile-menu-close {
            width: 20px;
            min-height: 20px;
            background: url('../img/icon-mobile-menu-close.svg');
            opacity: 1;
            padding: 0;
            border-radius: 0;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        .navbar-nav {
            margin-top: 25px;

            @include media-breakpoint-up(md) {
                margin-top: 0;
                flex-direction: column;
                flex-grow: 1;
                width: 100%;
                padding: 44px 0 0 0px;
            }

            .nav-item {
                padding: 11px 0 11px 20px;

                .nav-link:before {
                    content: "";
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    vertical-align: bottom;
                    padding-left: 20px;
                    padding-right: 20px;
                }

                .namizje:before {
                    background: url('../img/icon-menu-namizje.svg') no-repeat;
                }

                .management:before {
                    background: url('../img/icon-menu-management.svg') no-repeat;
                }

                .internet:before {
                    background: url('../img/icon-menu-internet.svg') no-repeat;
                }

                .prenocitve:before {
                    background: url('../img/icon-menu-prenocitve.svg') no-repeat;
                }

                .obvestila:before {
                    background: url('../img/icon-menu-obvestila.svg') no-repeat;
                }

                .okvare:before {
                    background: url('../img/icon-menu-okvare.svg') no-repeat;
                }

                .opomini:before {
                    background: url('../img/icon-menu-opomini.svg') no-repeat;
                }

                .izjave:before {
                    background: url('../img/icon-menu-izjave.svg') no-repeat;
                }

                .sport:before {
                    background: url('../img/icon-menu-sport.svg') no-repeat;
                }

                .podpora:before {
                    background: url('../img/icon-menu-podpora.svg') no-repeat;
                }

                .kuponi:before {
                    background: url('../img/icon-menu-kuponi.png') no-repeat;
                }

                .selitve:before {
                    background: url('../img/icon-menu-selitve.png') no-repeat;
                }

                .racuni:before {
                    background: url('../img/receipt-regular.svg') no-repeat;
                }

                .revizija:before {
                    background: url('../img/revision-regular.svg') no-repeat;
                }

                .reservations:before {
                    background: url('../img/icon-menu-reservations.svg') no-repeat;
                }
            }

            .nav-item.active {
                .namizje:before {
                    background: url('../img/icon-menu-namizje-active.svg') no-repeat;
                }

                .management:before {
                    background: url('../img/icon-menu-management-active.svg') no-repeat;
                }

                .internet:before {
                    background: url('../img/icon-menu-internet-active.svg') no-repeat;
                }

                .prenocitve:before {
                    background: url('../img/icon-menu-prenocitve-active.svg') no-repeat;
                }

                .obvestila:before {
                    background: url('../img/icon-menu-obvestila-active.svg') no-repeat;
                }

                .okvare:before {
                    background: url('../img/icon-menu-okvare-active.svg') no-repeat;
                }

                .opomini:before {
                    background: url('../img/icon-menu-opomini-active.svg') no-repeat;
                }

                .izjave:before {
                    background: url('../img/icon-menu-izjave-active.svg') no-repeat;
                }

                .sport:before {
                    background: url('../img/icon-menu-sport-active.svg') no-repeat;
                }

                .kuponi:before {
                    background: url('../img/icon-menu-kuponi-active.png') no-repeat;
                }

                .selitve:before {
                    background: url('../img/icon-menu-selitve-active.png') no-repeat;
                }

                .racuni:before {
                    background: url('../img/receipt-regular-active.svg') no-repeat;
                }

                .revizija:before {
                    background: url('../img/revision-active.svg') no-repeat;
                }

                .reservations:before {
                    background: url('../img/icon-menu-reservations-active.svg') no-repeat;
                }
            }

            .nav-item.active,
            .nav-item.active-hover {
                position: relative;
                background-color: $background-color;
                border-radius: 25px 0 0 25px;
                //transition-delay: 250ms;
                //transition-property: background-color, border-radius;
                transition: background-color 0.15s ease-in-out;
            }

            .nav-item.active:before {
                content: "";
                position: absolute;
                background-color: transparent;
                bottom: -50px;
                right: 0;
                height: 50px;
                width: 25px;
                border-top-right-radius: 25px;
                box-shadow: 0 -25px 0 0 $background-color;
                //transition-delay: 5000ms;
                //transition-property: background-color, box-shadow, border-top-right-radius, content, top;
            }

            .nav-item.active:after {
                content: "";
                position: absolute;
                background-color: transparent;
                top: -50px;
                right: 0;
                height: 50px;
                width: 25px;
                border-bottom-right-radius: 25px;
                box-shadow: 0 25px 0 0 $background-color;
                //transition-delay: 5000ms;
                //transition-property: background-color, box-shadow, border-bottom-right-radius, content, top;
            }

            .nav-link {
                color: $blue;
            }

            .nav-link.active {
                color: $orange;
            }

            .nav-link.selected {
                color: $purple !important;
            }

            // Meni drugi nivo
            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    padding: 2px 0 2px 47px;

                    .nav-link-secondary {
                        display: block;
                        padding: 2px;
                        text-decoration: none;
                        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

                        &:hover {
                            color: $orange;
                        }
                    }

                    .nav-link-secondary.selected {
                        color: $purple !important;
                    }
                }
            }
        }
    }

    .nav-link-secondary.active {
        color: $orange;
        font-weight: bold;
    }
}

.body-content {
    #env-content {
        width: 100%;
        z-index: 5;
        background-color: #F15B4E;
        color: white;
        display: flex;
        justify-content: center;
    }

    // #fullPage {
    // padding-top: 30px;
    // }
}

// Content
.container-fluid {
    padding-left: 20px;
    padding-right: 20px;

    @include media-breakpoint-up(md) {
        padding-left: 50px;
        padding-right: 50px;
    }

    .reservations {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: 50px 0;

        .description {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .title {
            display: flex;
            justify-content: space-between;
            font-size: 1.125rem;
        }

        .floor-plan-wrapper {
            display: flex;
            flex-direction: row;
            gap: 2rem;

            @media (max-width: 1440px) {
                flex-direction: column;
            }

            .map {
                display: flex;
                flex-direction: column;
                gap: 1.75rem;
                min-width: 488px;
                max-width: 1200px;

                .floor-plan {
                    position: relative;
                    display: flex;

                    @media (max-width: 1440px) {
                        width: 100%;
                    }

                    img {
                        width: 100%;
                        display: block;
                        border: 1px solid #E5E2E2;
                        border-radius: 25px;
                    }

                    #conferenceRoom {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        top: 14.7%;
                        left: 55.25%;
                        width: 29.6%;
                        height: 10.3%;
                        background: rgba(66, 114, 184, 0.5);
                    }

                    #conferenceRoom:hover {
                        cursor: pointer;
                        background: rgba(66, 114, 184, 0.6);
                    }

                    #entireRoom {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: 36.5%;
                        left: 52%;
                        width: 33%;
                        height: 26.5%;
                        background: rgba(66, 114, 184, 0.5);
                    }

                    #entireRoom:hover {
                        cursor: pointer;
                        background: rgba(66, 114, 184, 0.6);
                    }

                    .desk {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 6.5%;
                        height: 5.15%;
                        background: rgba(66, 114, 184, 0.5);
                    }

                    .desk:hover {
                        cursor: pointer;
                        background: rgba(66, 114, 184, 0.6);
                    }

                    .sixthRow {
                        top: 21.65%;
                    }

                    .fifthRow {
                        top: 32.8%;
                    }

                    .fourthRow {
                        top: 44%;
                    }

                    .thirdRow {
                        top: 55%;
                    }

                    .secondRow {
                        top: 66.2%;
                    }

                    .firstRow {
                        top: 77.3%;
                    }

                    #desk1,
                    #desk5,
                    #desk9,
                    #desk13,
                    #desk17,
                    #desk21 {
                        left: 11.8%;
                    }

                    #desk2,
                    #desk6,
                    #desk10,
                    #desk14,
                    #desk18,
                    #desk22 {
                        left: 18.9%;
                    }

                    #desk3,
                    #desk7,
                    #desk11,
                    #desk15,
                    #desk19,
                    #desk23 {
                        left: 26%;
                    }

                    #desk4,
                    #desk8,
                    #desk12,
                    #desk16,
                    #desk20,
                    #desk24 {
                        left: 33.1%;
                    }
                }
            }

            .reservation-details {
                display: flex;
                flex-direction: column;
                gap: 1.75rem;
                min-width: 488px;

                @media (max-width: 1440px) {
                    width: 100%;
                }

                #reservationForm {
                    display: flex;
                    flex-direction: column;
                    gap: 1.75rem;
                }

                .reservation-info-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 1.75rem;

                    .content {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        background: #ECE8E8 0% 0% no-repeat padding-box;
                        border-radius: 25px;
                        padding: 1.5rem;
                        gap: 2.25rem;

                        .roww {
                            display: flex;
                            justify-content: space-between;

                            &.form-content {
                                width: 100%;
                            }

                            .row-item {
                                display: flex;
                                flex-direction: column;
                                gap: 0.875rem;

                                label {
                                    color: #818181;
                                }

                                input.date {
                                    width: 50px;
                                    background-color: #ECE8E8;
                                    border: none;
                                    border-bottom: 3px solid #4272B8 !important;

                                    &.whole {
                                        width: 100px;
                                    }
                                }

                                textarea {
                                    border-radius: 15px;
                                    border: 3px solid #4272B8 !important;
                                    margin: 10px 0;
                                    padding: 15px !important;
                                    resize: none;
                                }

                                .checkbox-container {
                                    display: flex;
                                    gap: 0.5rem;
                                }
                            }

                            .user-list {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                border-top: 5px solid #FFFFFF;
                                border-bottom: 5px solid #FFFFFF;
                                padding: 0.438rem 0;

                                .user {
                                    padding: 0.438rem 0;
                                    border-bottom: 1px solid #FFFFFF;
                                }

                                .user:last-child {
                                    border-bottom: none;
                                }
                            }
                        }

                        #reservationSlot {
                            .td-icon {
                                width: 15px;
                            }
                            .editSlot {
                                display: flex;
                                justify-content: end;
                                cursor: pointer;
                                span {
                                    margin-right: 5px;
                                }
                                .view:before {
                                    background: url('../img/eye-regular.svg') no-repeat;
                                }
                                .view.active:before {
                                    background: url('../img/eye-regular-active.svg') no-repeat;
                                }
                                .edit:before {
                                    background: url('../img/icon-edit.svg') no-repeat;
                                }
                                .edit.active:before {
                                    background: url('../img/icon-edit-active.svg') no-repeat;
                                }
                            }
                        }

                        .add-slot-btn-wrapper {
                            display: flex;
                            justify-content: end;
                            cursor: pointer;

                            #addNewSlot {
                                border: none;
                                color: #4272B8;
                            }
                        }
                    }

                    #conferanceRoomReservationForm {
                        display: flex;
                        flex-direction: column;
                        gap: 1.75rem;
                    }

                    .buttons-wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 1.75rem;

                        @media (max-width: 1440px) {}

                        .buttons {
                            display: flex;
                            gap: 1.75rem;
                        }
                    }
                }
            }
        }
    }
}

// Header
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;

    .top-left {
        .title {
            h1 {
                font-size: 25px;
                font-weight: normal;
                padding: 0;
                margin: 0;
                color: $orange;

                @include media-breakpoint-up(md) {
                    font-size: 30px;
                }
            }
        }
    }

    .top-right {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }

        .profil-wrap {
            display: flex;
            align-items: center;

            .profil-user {
                text-align: right;
                font-size: 13px;
                font-weight: 500;
                margin-right: 15px;

                .name a {
                    text-decoration: none;
                    //color:$blue;
                }
            }

            .profil-avatar {
                height: 50px;
                width: 50px;
                background-color: $blue;
                border-radius: 50%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                text-transform: uppercase;
                flex-shrink: 0;
                margin-right: 15px;

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .profil-logout {
                flex-shrink: 0;
            }
        }
    }
}


// User info content
.user-info-wrap {
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .red {
        color: $red;
    }
}

.row .col-md-12:last-child .user-info-wrap:last-child {
    margin-bottom: 0;
}

//
.document-download {
    img {
        margin-right: 5px;
    }
}

.field-validation-error {
    color: $red;
}

.form-group {
    margin-bottom: 2em;
}

.login-wrap .form-group {
    margin-bottom: 0;
}

// mail alert
.mail-alert-wrap {
    position: sticky;
    top: 55px;
    z-index: 1000;
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-up(md) {
        top: 0;
        padding-left: 50px;
        padding-right: 50px;
    }
}

.mail-alert-wrap.header-mail-alert {
    position: fixed;
    left: 0;

    @include media-breakpoint-up(md) {
        left: auto;
        padding-left: 0;
    }
}

.mail-alert {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 15px;
    font-size: 15px;
    padding: 1rem;
    background-color: #f7d9d6;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @include media-breakpoint-up(md) {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
    }

    .title {
        flex-shrink: 0;
        font-weight: bold;
        color: $red;
    }

    .text {}
}

// Avatar image
.avatar-image-wrap {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    background-color: #E5E2E2;

    @include media-breakpoint-up(sm) {
        width: 100px;
        height: 100px;
    }

    img {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

//Download button
.right {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    gap: 5px;
}

.img-download {
    max-height: 2em;
    width: auto;
}

.between {
    justify-content: space-between;
}

.izvoz {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-right: 25px;
    padding-bottom: 20px;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 25px;
    }

    /*margin-bottom: 30px;
    margin-right: 60px;
    font-size: 1em;
    font-weight: 500;
    align-items: center;
    width: -webkit-fill-available;*/
}

.btn-download {
    white-space: nowrap;
    display: flex;
    gap: 5px;
    align-items: center;
}

.btn-izvoz {
    width: fit-content;

    &:after {
        content: "";
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-left: 5px;
        background: url(../img/icon-circle-download-white.svg) no-repeat;
    }
}

//
.form-control:disabled {
    color: #818181;
    border-bottom-color: #818181;
}

.form-check-input {
    &.udelezba-checkbox {
        width: 1.5em;
        height: 1.5em;
    }
}

[inert] {
    pointer-events: none;
    cursor: default;
}

[inert] * {
    user-select: none;
}