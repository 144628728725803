
$font-family-sans-serif: "Red Hat Display" !default;
$font-size-base: 0.9375rem;
$font-weight-normal: 500;
$link-decoration: none !default;
$body-color: #333333 !default;

// Colors
$background-color: #fcf6f6;
//$blue: #185A9D !default;
$blue: #4272B8 !default;
$dark-blue: #1B4E82; // tabele
$purple: #5E136B !default;
//$red: #B83D5D !default;
$red: #F15B4E !default;
$green: #83B846 !default;
$orange: #FAA830 !default;
// Forms
$input-color: $dark-blue;
//$form-select-color: #E5E2E2;
//
//$input-border-color: $dark-blue !default;
$input-border-color: $blue !default;
$input-btn-border-width: 2px;
$input-border-radius: 0;
$input-color: $dark-blue !default;
$input-placeholder-color: $dark-blue !default;
$input-focus-box-shadow: transparent;
$form-select-focus-box-shadow: transparent;
$input-focus-border-color:$orange;

// Buttons
//$btn-padding-y: 0.75rem !default;
$btn-padding-x: 1.5rem;
$btn-border-radius:10px;
$btn-border-radius-sm: 10px;
//$form-check-input-border: red;
$form-check-input-border: 2px solid rgba($blue, 1);
$form-check-input-checked-bg-color: $blue;
$input-border-width: 3px;
$btn-font-size-lg: 15px;


.form-control, .form-select {
    border-top-style: none !important;
    border-right-style: none !important;
    border-left-style: none !important;
    background-color: transparent !important;
    background-position: right center !important;
}

// Accordion
$accordion-color: red !default;
$accordion-icon-transform: rotate(-90deg) !default;

// Popover
$popover-border-color: #E5E2E2;
$popover-border-radius:15px;

// Modal
$modal-content-border-radius:25px;



