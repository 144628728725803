// Accordion
.accordion-item {
    color: $body-color;
    margin-bottom: 10px;
    background-color: #E5E2E2;
    border: 1px solid #E5E2E2 !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;

    &.active {
        .accordion-header {
            background-color: #fff;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .accordion-header {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 20px 25px 20px 15px;
        background-color: #E4E6ED;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;

        .accordion-button {
            width: auto;
            background-color: transparent;
            padding: 0;

            &:after {
                background-image: url('../img/icon-right.svg');
                background-repeat: no-repeat;
                height: 15px;
                width: 9px;
                background-size: contain;
            }

            &:not(.collapsed) {
                box-shadow: none;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .label {
            font-size: 13px;
            font-weight: 700;
        }

        .data {
            font-size: 14px;
        }

        .ah-name {
            flex-basis: 25%;
            font-size: 16px;
            font-weight: 700;
        }

        .ah-date {
            flex-basis: 12%;

            .label {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 12px;
                    height: 13px;
                    vertical-align: text-top;
                    margin-right: 7px;
                    background: url('../img/icon-calendar-small.svg') no-repeat;
                }
            }

            .data {
                margin-left: 19px;
            }
        }

        .ah-location {
            flex-basis: 14%;

            .label {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 11px;
                    height: 14px;
                    vertical-align: text-top;
                    margin-right: 7px;
                    background: url('../img/icon-location-small.svg') no-repeat;
                }
            }

            .data {
                margin-left: 18px;
            }
        }

        .ah-price {
            flex-basis: 8%;
        }

        .ah-button {
            flex-basis: 12%;

            .btn {
                width: 100%;
            }

            .registered {
                font-size: 13px;
                color: $green;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                &:before {
                    flex-shrink: 0;
                    content: "";
                    width: 18px;
                    height: 18px;
                    margin-right: 7px;
                    background: url('../img/icon-green-checkmark-outline.svg') no-repeat;
                }

                @include media-breakpoint-up(xl) {
                    justify-content: center;
                }
            }
        }

        .ah-number {
            display: flex;
            justify-content: center;
            flex-basis: 10%;

            .subs-number {
                display: flex;
                justify-content: center;
                font-size: 13px;
                font-weight: 600;
                color: $blue;
                border: 2px solid $blue;
                border-radius: 10px;
                width: fit-content;
                padding: 5px 15px 5px 15px;

                &:before {
                    content: "";
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    margin-right: 7px;
                    vertical-align: bottom;
                    background: url('../img/icon-subscribers.svg') no-repeat;
                    filter: invert(24%) sepia(99%) saturate(894%) hue-rotate(184deg) brightness(96%) contrast(93%);
                }
            }
        }

        .ah-status {
            flex-basis: 12%;
            font-size: 14px;

            &.aktivno {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    margin-right: 7px;
                    vertical-align: baseline;
                    background: url('../img/icon-green-dot-big.svg') no-repeat;
                }
            }

            &.neaktivno {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    margin-right: 7px;
                    vertical-align: baseline;
                    background: url('../img/icon-gray-dot-big.svg') no-repeat;
                }
            }
        }

        .ah-edit {
            margin-left: auto;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 34px;
                width: 34px;

                &:hover {
                    background: url('../img/icon-circle.svg') no-repeat;
                }
            }
        }
    }

    .accordion-body {
        font-size: 14px;
        padding: 20px 25px 20px 34px;
        margin-top: 5px;
        background-color: #fff;
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        //border: 1px solid #E5E2E2 !important;
        p {
            margin-bottom: .5rem;
        }

        ul.green-dots {
            margin: 0;

            li {
                padding-left: 17px;
                text-indent: -17px;

                &:before {
                    margin-right: 8px;
                }
            }
        }

        ul.black-dots {
            margin: 0;

            li {
                padding-left: 17px;
                text-indent: -17px;

                &:before {
                    margin-right: 8px;
                }
            }
        }
        /*.ah-number {
            display: flex;
            margin: 0px -20px 0px -20px;
            padding: 10px 0px 0 20px;

            .subs-number {
                display: flex;
                justify-content: center;
                font-size: 13px;
                font-weight: 600;
                color: $blue;
                border: 2px solid $blue;
                border-radius: 10px;
                width: fit-content;
                padding: 5px 35px 5px 35px;

                &:before {
                    content: "";
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    margin-right: 7px;
                    vertical-align: bottom;
                    background: url('../img/icon-subscribers.svg') no-repeat;
                    filter: invert(24%) sepia(99%) saturate(894%) hue-rotate(184deg) brightness(96%) contrast(93%);
                }
            }
        }*/
    }
}
    // Accordion - Mobile
.accordion-mobile {
    .accordion-item {

        &.ordered {
            .accordion-header {
                background-color: $purple;

                .ah-name {
                    color: #fff;
                }

                .right-wrap {
                    .ah-price {
                        .data {
                            color: #fff;
                        }
                    }
                }

                .accordion-button {

                    &:after {
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(206deg) brightness(101%) contrast(103%);
                    }

                    &:not(.collapsed)::after {
                        filter: none;
                    }
                }
            }
        }

        &.active.ordered {
            .accordion-header {
                background-color: #fff;

                .ah-name {
                    color: $body-color;
                }

                .right-wrap {
                    .ah-price {
                        .data {
                            color: $body-color;
                        }
                    }
                }
            }
        }

        .accordion-header {
            padding: 20px;
            justify-content: space-between;

            .accordion-button {

                &:after {
                    transform: rotate(90deg);
                }

                &:not(.collapsed)::after {
                    transform: rotate(-90deg);
                }
            }

            .ah-name {
                flex-basis: auto;
            }

            .right-wrap {
                display: flex;

                .ah-price {
                    color: #AAAAAA;
                    flex-basis: auto;
                    text-align: right;
                    margin-right: 20px;

                    .label {
                        color: #AAAAAA;
                    }

                    .data {
                        font-size: 18px;
                        color: $body-color;
                        font-weight: 700;
                    }
                }
            }
        }

        .accordion-body {
            padding: 20px;

            .edit-order-wrap {
                display: flex;
                margin: 20px -20px 20px -20px;
                padding: 20px 20px 0 20px;
                border-top: 1px solid #E5E2E2;

                .ah-date {
                    flex-basis: 50%;

                    .label {
                        font-size: 13px;

                        &:before {
                            content: "";
                            display: inline-block;
                            width: 12px;
                            height: 13px;
                            vertical-align: text-top;
                            margin-right: 7px;
                            background: url('../img/icon-calendar-small.svg') no-repeat;
                        }
                    }

                    .data {
                        font-size: 14px;
                        margin-left: 19px;
                    }
                }

                .ah-location {
                    flex-basis: 50%;

                    .label {
                        font-size: 13px;
                        font-weight: 700;

                        &:before {
                            content: "";
                            display: inline-block;
                            width: 11px;
                            height: 14px;
                            vertical-align: text-top;
                            margin-right: 7px;
                            background: url('../img/icon-location-small.svg') no-repeat;
                        }
                    }

                    .data {
                        font-size: 14px;
                        margin-left: 18px;
                    }
                }
            }

            .registered {
                font-size: 13px;
                color: $green;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                &:before {
                    flex-shrink: 0;
                    content: "";
                    width: 18px;
                    height: 18px;
                    margin-right: 7px;
                    background: url('../img/icon-green-checkmark-outline.svg') no-repeat;
                }

                @include media-breakpoint-up(xl) {
                    justify-content: center;
                }
            }

            .subs-number {
                display: flex;
                justify-content: center;
                font-size: 13px;
                font-weight: 600;
                color: $blue;
                border: 2px solid $blue;
                border-radius: 10px;
                width: fit-content;
                padding: 5px 15px 5px 15px;

                &:before {
                    content: "";
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    margin-right: 7px;
                    vertical-align: bottom;
                    background: url('../img/icon-subscribers.svg') no-repeat;
                    filter: invert(24%) sepia(99%) saturate(894%) hue-rotate(184deg) brightness(96%) contrast(93%);
                }
            }

            .btn {
                margin-top: 10px;
            }
        }
    }
}

// Accordion - Izjava o izselitvi
.accordion.izjava-izselitev {
    .accordion-item {
        &.active {
            .accordion-header {
                background-color: #fcf6f6;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .accordion-header {
        background-color: #E4E6ED;

        .title {
            font-size: 16px;
            font-weight: 700;
        }
    }

    .accordion-body {
        background-color: #fcf6f6;
        margin-top:1px;

        h3 {
            font-size: 18px;
            font-weight: 500;
        }
    }
}