.select2 {
    width: 100% !important;
}

.select2-container--default .select2-selection--multiple {
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 3px solid $blue;
    border-radius: 0;
    padding: 5px 5px 5px 0;
    min-height: 45px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 3px solid $blue;
    outline: 0;
}

.select2-container--default .select2-selection--single {
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 3px solid $blue;
    border-radius: 0;
}

.select2-container .select2-selection--single {
    height: 45px;
    
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 45px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 45px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: $blue transparent transparent transparent;
}

.select2-dropdown {
    border: 1px solid $blue;
    border-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid $blue;
}

.select2-search.select2-search--inline {
    display:none;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: $blue;
    border: 1px solid $blue;
    color:#fff;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff;
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), none;
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
    border-color: $blue;
}

.was-validated .form-control:valid, .form-control.is-valid {
    border-color: $blue;
    background-image: none;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: $orange;
    box-shadow: 0 0 0 0.25rem rgba(250, 168, 48, 0.25);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: $orange;
    box-shadow: 0 0 0 0.25rem rgba(250, 168, 48, 0.25);
}

.form-select.is-invalid ~ .select2 .selection .select2-selection--multiple {
    border-color: #F15B4E;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F15B4E'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F15B4E' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}