// Table

table {
    font-size: 14px;
    .w-350 {
        width: 350px;
    }
    th {
        border-bottom: solid #E5E2E2;
        border-bottom-width: 5px !important;
    }

    td {
        vertical-align: middle;

        .status {
            display: flex;
            align-items: center;
        }

        .status.aktivno:before,
        .status.sprejeto:before {
            flex-shrink: 0;
            content: "";
            width: 12px;
            height: 12px;
            margin-right: 7px;
            background: url(../img/icon-green-dot-big.svg) no-repeat;
        }

        .status.zamujeno:before,
        .status.neaktivno:before,
        .status.nalozeno:before {
            flex-shrink: 0;
            content: "";
            width: 12px;
            height: 12px;
            margin-right: 7px;
            background: url(../img/icon-yellow-dot-big.svg) no-repeat;
        }

        .status.osnutek:before,
        .status.preteceno:before {
            flex-shrink: 0;
            content: "";
            width: 12px;
            height: 12px;
            margin-right: 7px;
            background: url(../img/icon-gray-dot-big.svg) no-repeat;
        }

        .action-buttons {
            display: flex;
            column-gap: 5px;

            .circle-accept {
                width: 35px;
                height: 35px;
                background: url(../img/icon-circle-accept.svg) no-repeat;
            }

            .circle-decline {
                width: 35px;
                height: 35px;
                background: url(../img/icon-circle-decline.svg) no-repeat;
            }
        }
    }

    .col-sort {
        a {
            margin-left: 10px;
            vertical-align: text-bottom;
        }
    }

    .td-icon {
        min-width: 15px;
        margin-right: 5px;

        & + span {
            color: $blue;
            //margin-left: 5px;
            vertical-align: middle;
        }
    }

    a:hover {
        //opacity: 0.85;

        .td-icon {
            opacity: 0.85;
        }
    }

    tr.table-active {
        font-weight: bold;
        --bs-table-accent-bg: #f0eef2;
    }

    .align-top {
        td {
            vertical-align: top;
        }
    }

    .th-stat {
        width: 150px;
    }

    .table-stat {
        //width: min-content;

        table {
            margin: 0;

            th {
                font-weight: normal;
                color: #9e9ea0;
                border-bottom-width: 0 !important;
                padding: 3px 3px 3px 0px;
            }

            td {
                padding: 3px 0px 3px 3px;
            }
        }
    }
}

.pagination {
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px 0;

    .page-item {
        margin: 0 10px 0 10px;

        .page-link-first {
            display: inline-block;
            width: 20px;
            height: 17px;
            vertical-align: middle;
            //margin-left: 7px;
            background: url('../img/icon-pagination-first.svg') no-repeat;
        }

        .page-link-last {
            display: inline-block;
            width: 20px;
            height: 17px;
            vertical-align: middle;
            //margin-left: 7px;
            background: url('../img/icon-pagination-last.svg') no-repeat;
        }

        .page-link-previous {
            display: inline-block;
            width: 16px;
            height: 14px;
            vertical-align: middle;
            //margin-left: 7px;
            background: url('../img/icon-pagination-previous.svg') no-repeat;
        }

        .page-link-next {
            display: inline-block;
            width: 16px;
            height: 14px;
            vertical-align: middle;
            //margin-left: 7px;
            background: url('../img/icon-pagination-next.svg') no-repeat;
        }
    }
}

// Subscriptions DESKTOP - Table
.subscriptions {
    font-size: 14px;

    table {
        td {
            vertical-align: middle;
        }
    }

    .name {
        color: $blue;
        font-weight: 700;
    }

    .subs-number {
        display: flex;
        justify-content: center;
        font-size: 13px;
        font-weight: 600;
        color: $blue;
        border: 2px solid $blue;
        border-radius: 10px;
        width: fit-content;
        padding: 3px 15px 3px 15px;

        &:before {
            content: "";
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-right: 7px;
            vertical-align: bottom;
            background: url(../img/icon-subscribers.svg) no-repeat;
            filter: invert(24%) sepia(99%) saturate(894%) hue-rotate(184deg) brightness(96%) contrast(93%);
        }
    }

    .legals {
        .form-check {
            text-align: center;

            input {
                float: none;
            }
        }
    }

    .status {
        display: flex;
        align-items: center;
    }

    .status.aktivno:before {
        flex-shrink: 0;
        content: "";
        width: 12px;
        height: 12px;
        margin-right: 7px;
        background: url(../img/icon-green-dot-big.svg) no-repeat;
    }

    .status.zamujeno:before,
    .status.neaktivno:before {
        flex-shrink: 0;
        content: "";
        width: 12px;
        height: 12px;
        margin-right: 7px;
        background: url(../img/icon-yellow-dot-big.svg) no-repeat;
    }

    .status.osnutek:before,
    .status.preteceno:before {
        flex-shrink: 0;
        content: "";
        width: 12px;
        height: 12px;
        margin-right: 7px;
        background: url(../img/icon-gray-dot-big.svg) no-repeat;
    }

    .edit {
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 34px;
            width: 34px;
            margin-left: auto;

            &:hover {
                background: url(../img/icon-circle.svg) no-repeat;
            }
        }
    }
}

// Subscriptions MOBILE
.subscriptions.subscriptions-mobile {
    .tile {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 20px;
        }
    }

    .name {
        font-size: 16px;
    }

    .label {
        font-size: 13px;
        margin-bottom: 0;
        text-align: right;
    }

    .price {
        font-size: 18px;
        font-weight: 700;
        text-align: right;
    }

    .btn {
        margin-top: 10px;
    }
}

.recurring-event {
    font-weight: 600!important;
    margin-top: 4px;
}
