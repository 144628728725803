// Tiles
.tile-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .tile {
        flex-grow: 1;
    }

    .tile-footer {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: -7px;
        padding: 0 25px 0 0;

        .arrow-more:after {
            content: "";
            display: inline-block;
            width: 24px;
            height: 24px;
            vertical-align: middle;
            margin-left: 7px;
            background: url(../img/icon-arrow-right.svg) no-repeat;
        }
    }

    
}

.tile-header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 10px 0 10px 0;
    padding: 0 25px 0 25px;
    min-height: 40px;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        align-items: center;
    }

    h2 {
        font-size: 21px;
        color: $green;
        font-weight: 400;
    }

    .title {
        font-size: 18px;
        //color: $green;

        &.red {
            color: $red;
        }

        @include media-breakpoint-up(md) {
            font-size: 18px;
            //line-height: 37px;
        }

        .circle-number {
            font-size: 15px;
            height: 30px;
            width: 30px;
            background-color: $blue;
            border-radius: 50%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            text-transform: uppercase;
            margin: 0 0 0 10px;
        }
    }

    .controls {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        font-size: 15px;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
            align-items: center;
        }

        &.icon-only {
            flex-direction: row;

            a {
                margin-left: 10px;
            }

            .add:after,
            .next:after {
                margin-left: 0 !important;
            }
        }

        a {
            margin-left: 0;
            padding: 5px 0 5px 0;

            &:first-child {
                margin-left: 0;
            }

            @include media-breakpoint-up(sm) {
                margin-left: 10px;
            }
        }

        .add:after {
            content: "";
            display: inline-block;
            width: 30px;
            height: 30px;
            vertical-align: middle;
            margin-left: 7px;
            background: url('../img/icon-add.svg') no-repeat;
        }

        .next:after {
            content: "";
            display: inline-block;
            width: 30px;
            height: 30px;
            vertical-align: middle;
            margin-left: 7px;
            background: url('../img/icon-next.svg') no-repeat;
        }

        .circle-number {
            height: 30px;
            width: 30px;
            background-color: $blue;
            border-radius: 50%;
            display: inline-flex;
            //align-items: center;
            justify-content: center;
            color: #fff;
            text-transform: uppercase;
            margin: 5px 0 5px 0;
        }
    }
}

.tile-subheader {
    display: flex;
    margin: 0 0 10px 0;
    padding: 0 25px 0 25px;
    
    .title {

    }
}
// Tile standard
.tile {
    margin: 0px 0 20px 0;
    padding: 25px;
    border: 1px solid #E5E2E2;
    border-radius: 25px;

    h3 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;

        &.blue {
            color: $blue;
        }
    }

    .label {
        margin-bottom: 5px;

        &.light {
            color: #9e9ea0;
        }
    }

    .data {
        &.blue {
            color: $blue;
        }

        &.red {
            color: $red;
        }

        &.green {
            color: $green;
        }

        &.green-check-after:after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            vertical-align: top;
            margin-left: 7px;
            background: url('../img/icon-green-checkmark.svg') no-repeat;
        }

        &.green-check-before:before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            vertical-align: top;
            margin-right: 7px;
            background: url('../img/icon-green-checkmark.svg') no-repeat;
        }

        &.red-check-after:after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            vertical-align: top;
            margin-left: 7px;
            background: url('../img/icon-red-checkmark.svg') no-repeat;
        }

        &.red-check-before:before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            vertical-align: top;
            margin-right: 7px;
            background: url('../img/icon-red-checkmark.svg') no-repeat;
        }
    }

    .info {
        font-size: 12px;
    }

    &.borderless {
        border: none;
    }

    &.white {
        background-color: #fff;
    }

    &.red {
        color: #fff;
        background-color: $red;

        .label {
            color: #d48a9d;
        }
    }

    &.gray {
        background-color: #ECE8E8;
        border: 1px solid #E8E8E9;
    }

    &.inactive {
        background-color: #EEEEEE;
        border: 1px solid #E8E8E9;
    }

    &.checked {
        background-color: #E4E6ED;
        border: 1px solid #E8E8E9;
    }

    &.smaller {
        padding: 15px 25px 15px 25px;
        border-radius: 25px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 20px;
        }

        @include media-breakpoint-up(xl) {
            border-radius: 15px;
        }
    }

    &.shadow {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .table-intro {
        font-size: 16px;

        @include media-breakpoint-up(md) {
            font-size: 18px;
        }
    }

    p:last-child {
        margin-bottom: 0;
    }

    .refresh {
        font-size: 21px;
        font-weight: 700;

        a {
            font-size: initial;
            font-weight: normal;
            margin-left: 10px;

            &:before {
                content: "";
                display: inline-block;
                width: 12px;
                height: 12px;
                margin-right: 7px;
                background: url('../img/icon-refresh.svg') no-repeat;
            }
        }
    }

    .graf-header {
        display: flex;
        padding-bottom: 10px;
        border-bottom: 5px solid #E5E2E2;

        .name {
            padding: 5px 10px 5px 0;
        }

        .data {
            color: $red;
            padding: 5px 0 5px 10px;
            border-left: 1px solid #E5E2E2;
        }
    }
}

// Tile z tile top

.tile-top {
    margin: 0;
    padding: 25px;
    border: 1px solid #E5E2E2;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: #ECE8E8;
}

.tile-content {
    margin-bottom: 20px;
    padding: 25px;
    border: 1px solid #E5E2E2;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;

    &.white {
        background-color: #fff;
    }

    p:last-child {
        margin-bottom: 0;
    }
}


// Tile Activities
.activities {
    font-size: 14px;
    align-items: center;

    .order-6 {
        order: 6;
    }

    .order-xl-6 {
        order: 6;
    }

    .order-7 {
        order: 7;
    }

    .order-xl-7 {
        order: 7;
    }

    .order-8 {
        order: 8;
    }

    .order-xl-8 {
        order: 8;
    }

    .order-9 {
        order: 9;
    }

    .order-xl-9 {
        order: 9;
    }

    .order-10 {
        order: 10;
    }

    .order-xl-10 {
        order: 10;
    }

    .order-11 {
        order: 11;
    }

    .order-xl-11 {
        order: 11;
    }

    .order-12 {
        order: 12;
    }

    .order-xl-12 {
        order: 12;
    }

    .name {
        font-size: 15px;
        color: $blue;
        font-weight: 700;

        @include media-breakpoint-up(xl) {
            font-size: 14px;
        }
    }

    .date {
        display: flex;
        align-items: center;

        &:before {

            @include media-breakpoint-up(xl) {
                flex-shrink: 0;
                content: "";
                width: 12px;
                height: 13px;
                margin-right: 7px;
                background: url('../img/icon-calendar-small.svg') no-repeat;
            }
        }
    }

    .location {
        display: flex;
        align-items: center;
        color: $red;

        &:before {

            @include media-breakpoint-up(xl) {
                flex-shrink: 0;
                content: "";
                width: 11px;
                height: 14px;
                margin-right: 7px;
                background: url('../img/icon-location-small.svg') no-repeat;
            }
        }

        @include media-breakpoint-up(xl) {
            color: $body-color;
        }
    }

    .subs-number {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &:before {
            flex-shrink: 0;
            content: "";
            width: 18px;
            height: 18px;
            margin-right: 7px;
            background: url('../img/icon-subscribers-gray.svg') no-repeat;
        }

        @include media-breakpoint-up(xl) {
            justify-content: flex-start;
        }
    }

    .subs-number-admin {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 13px;
        font-weight: 600;
        color: $blue;
        border: 2px solid $blue;
        border-radius: 10px;
        width: fit-content;
        padding: 3px 15px 3px 15px;

        &:before {
            content: "";
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-right: 7px;
            vertical-align: bottom;
            background: url(../img/icon-subscribers.svg) no-repeat;
            filter: invert(24%) sepia(99%) saturate(894%) hue-rotate(184deg) brightness(96%) contrast(93%);
        }

        @include media-breakpoint-up(xl) {
            justify-content: flex-start;
        }
    }

    .registered {
        font-size: 13px;
        color: $green;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &:before {
            flex-shrink: 0;
            content: "";
            width: 18px;
            height: 18px;
            margin-right: 7px;
            background: url('../img/icon-green-checkmark-outline.svg') no-repeat;
        }

        @include media-breakpoint-up(xl) {
            justify-content: center;
        }
    }

    .document {
        a {
            display: flex;
            align-items: center;

            img {
                min-width: 15px;
                margin-right: 5px;
            }
        }
    }

    .status {
        display: flex;
        align-items: center;

        &.aktivno {
            &:before {
                flex-shrink: 0;
                content: "";
                width: 12px;
                height: 12px;
                margin-right: 7px;
                background: url('../img/icon-green-dot-big.svg') no-repeat;
            }
        }
    }

    .status.zamujeno:before,
    .status.neaktivno:before {
        flex-shrink: 0;
        content: "";
        width: 12px;
        height: 12px;
        margin-right: 7px;
        background: url(../img/icon-yellow-dot-big.svg) no-repeat;
    }

    .status.osnutek:before,
    .status.preteceno:before {
        flex-shrink: 0;
        content: "";
        width: 12px;
        height: 12px;
        margin-right: 7px;
        background: url(../img/icon-gray-dot-big.svg) no-repeat;
    }

    .edit {
        display: flex;
        align-items: center;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 34px;
            width: 34px;
            margin-left: auto;

            &:hover {
                background: url('../img/icon-circle.svg') no-repeat;
            }
        }
    }
}

// Tile Activities Day
.activities.day-activities {
    .registered {
        justify-content: flex-end;

        @include media-breakpoint-up(xl) {
            justify-content: flex-start;
        }
    }

    .btn-col {
        display: flex;
        justify-content: flex-end;

        @include media-breakpoint-up(xl) {
            justify-content: flex-start;
        }
    }
}

.btn-row-main {
    display: flex;
    column-gap: 10px;
    margin: 20px 0 20px 0;
}

.btn-row {
    display: flex;
    column-gap: 10px;
    padding: 0 20px 0 20px;
    margin-bottom: 20px;

    .after-plus {
        display: flex;
        align-items: center;
        width: fit-content;

        &:after {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-left: 10px;
            background: url(../img/icon-plus.svg) no-repeat;
        }
    }

    .before-plus {
        display: flex;
        align-items: center;
        width: fit-content;

        &:before {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-right: 10px;
            background: url(../img/icon-plus.svg) no-repeat;
        }
    }
}

// Tile - Obvestila podrobno
.obvestila-podrobno {
    position: relative;

    .btn-back {
        position: absolute;
        top: -20px;
        right: -20px;
        width: 46px;
        height: 46px;
        background: url(../img/icon-circle-close.svg) no-repeat;
    }

    .tile-top {
        padding: 30px 20px 30px 20px;

        @include media-breakpoint-up(md) {
            padding: 50px;
        }

        .date {
            font-size: 13px;
        }

        .title {
            font-size: 28px;
            font-weight: 700;
            margin: 0 0 10px 0;
        }

        .from {
            font-size: 13px;
            color: #9e9ea0;

            span {
                color: initial;
            }
        }
    }

    .tile-content {
        padding: 30px 20px 30px 20px;

        @include media-breakpoint-up(md) {
            padding: 50px;
        }

        .attachments-sidebar {
            border: 1px solid #E7E5E5;
            padding: 20px;
            border-radius: 15px;

            ul {
                list-style: none;
                margin: 5px 0 0 0;
                padding: 0;
                font-size: 14px;

                li {
                    a {
                        display: flex;
                        align-items: baseline;
                        word-break: break-all;

                        &:before {
                            content: "";
                            display: inline-block;
                            min-width: 11px;
                            height: 12px;
                            margin-right: 8px;
                            background: url(../img/icon-blue-attachment-small.svg) no-repeat;
                        }
                    }
                }
            }
        }
    }
}

// Tile - Sport podrobno
.sport-podrobno {
    position: relative;

    .btn-back {
        position: absolute;
        top: -20px;
        right: -20px;
        width: 46px;
        height: 46px;
        background: url(../img/icon-circle-close.svg) no-repeat;
    }

    .tile-top {
        padding: 30px 20px 30px 20px;

        @include media-breakpoint-up(md) {
            padding: 50px;
        }

        .date {
            font-size: 13px;
        }

        .title {
            font-size: 28px;
            font-weight: 700;
            margin: 0 0 10px 0;
        }

        .from {
            font-size: 13px;
            color: #9e9ea0;

            span {
                color: initial;
            }
        }
    }

    .tile-content {
        padding: 20px 20px 20px 20px;

        @include media-breakpoint-up(md) {
            padding: 10px 50px 20px 50px;
        }

        .tile-top {
            padding: 25px;
        }

        .tile-content {
            padding: 25px;
        }

        .img-avatar {
            border-radius: 50%;
        }
    }
}

.btn-row-col {
    margin-bottom: 20px;

    .btn-row {
        justify-content: flex-start;
        padding: 0;
        margin-bottom: 0;
        flex-wrap:wrap;
        row-gap:20px;

        @include media-breakpoint-up(xl) {
            justify-content: flex-end;
        }
    }
}
// Message / Attachments
.edit-attachments {
    .title {
        &:before {
            content: "";
            display: inline-block;
            width: 14px;
            height: 15px;
            margin-right: 10px;
            background: url(../img/icon-attachment.svg) no-repeat;
        }
    }

    .add-btn {
        margin: 10px 0 10px 0;

        .before-plus {
            display: flex;
            align-items: center;
            width: fit-content;

            &:before {
                content: "";
                display: inline-block;
                width: 12px;
                height: 12px;
                margin-right: 10px;
                background: url(../img/icon-plus.svg) no-repeat;
            }
        }
    }

    ul.attachments {
        color: $blue;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: flex;
            justify-content: space-between;
            column-gap: 10px;
            border: 1px solid #E5E2E2;
            border-radius: 10px;
            background-color: #fff;
            margin-bottom: 5px;
            padding: 10px 20px 10px 20px;

            .remove-attachment {
                flex-shrink: 0;
            }
        }
    }
}

.edit-accessories {
    .title {
    }

    .add-btn {
        margin: 10px 0 10px 0;

        .before-plus {
            display: flex;
            align-items: center;
            width: fit-content;

            &:before {
                content: "";
                display: inline-block;
                width: 12px;
                height: 12px;
                margin-right: 10px;
                background: url(../img/icon-plus.svg) no-repeat;
            }
        }
    }

    ul.accessories {
        color: $blue;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 10px;
            border-bottom: 1px solid #E5E2E2;
            margin-bottom: 5px;
            padding: 5px;

            .remove-attachment {
                flex-shrink: 0;
                margin-left:20px;
            }

            .edit {
                display: flex;
                align-items: center;

                .form-select {
                    width: auto;
                    margin-right:10px;
                }
            }
        }
    }
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple]):not([size]) {
    //background-position: right 0.75rem center, center right 2.25rem !important;
    background-position: right 0rem center, center right 1.25rem !important;
    //background-position: right 0.75rem, center 2.25rem !important;
}

// Chart
.apexcharts-legend-series.custom-marker-down .apexcharts-legend-marker {
    background: url(../img/icon-triangle-green-down.svg) no-repeat !important;
    color: $green !important;
    width: 15px !important;
    height: 13px !important;
}

.apexcharts-legend-series.custom-marker-up .apexcharts-legend-marker {
    background: url(../img/icon-triangle-blue-up.svg) no-repeat !important;
    color: $blue !important;
    width: 15px !important;
    height: 13px !important;
}


//fitnes card
.fitness-card {
    align-items: center;

    @include media-breakpoint-down(sm) {
        //margin: 25px;
    }

    .fitnes-card-home {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;

        .center {
            margin: auto;
        }
    }


    .btn-close {
        position: absolute;
        width: 46px;
        height: 46px;
        background: url('../img/icon-circle-close.svg') no-repeat;
        padding: 0;
        margin: 0;
        top: -20px;
        right: -20px;
        opacity: 1;
    }

    .modal-header {
        flex-direction: column;
        align-items: flex-start;
        background-color: #F2EEF1;
        padding: 25px;

        @include media-breakpoint-up(lg) {
            padding: 50px;
        }

        .title {
            display: flex;
            align-items: baseline;
            column-gap: 15px;
            font-size: 28px;
            font-weight: 700;
            margin: 0 0 10px 0;

            .edit {
                font-size: 15px;
                font-weight: normal;

                a {
                    display: flex;

                    &:before {
                        content: "";
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                        background: url(../img/icon-edit.svg) no-repeat;
                    }
                }
            }
        }

        .from {
            font-size: 13px;
            color: #9e9ea0;

            span {
                color: initial;
            }
        }
    }
    /*padding: 25px;

    @include media-breakpoint-up(lg) {
        padding: 50px;
    }*/

    .title {
        font-size: 21px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .tile {
        margin-bottom: 10px;

        .lb-wrap {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            .phone {
                a {
                    font-size: 15px;
                    display: flex;
                    align-items: center;

                    &:before {
                        content: "";
                        width: 13px;
                        height: 13px;
                        margin-right: 10px;
                        background: url(../img/icon-phone.svg) no-repeat;
                    }
                }
            }
        }

        .label {
            font-weight: 700;
        }

        .data {
            font-size: 14px;
        }

        ul.data {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        ul.attachments {
            a {
                display: flex;
                align-items: center;

                &:before {
                    content: "";
                    width: 11px;
                    height: 12px;
                    margin-right: 10px;
                    background: url(../img/icon-blue-attachment-small.svg) no-repeat;
                }
            }
        }
    }

    .mb-footer {
        margin-top: 40px;
        padding: 20px 0 10px 0;
        border-top: 1px solid #E5E2E2;
    }






    .title {
        font-size: 30px;
        font-weight: normal;
        margin-bottom: 20px;
    }

    .avatar {
        max-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        place-items: center;
        gap: 20px;

        img {
            border-radius: 50%;
            height: auto;
            width: 100%;
        }

        .modal-upload-buttons {
            margin-bottom: 20px;

            .add-btn {
                margin-bottom: 20px;
            }
        }
    }

    .profile {
        background-color: #fff;
        flex-grow: 1;
        margin: 0px 0 20px 0;
        padding: 25px;
        border: 1px solid #E5E2E2;
        border-radius: 25px;
        display: block;


        .data {
            .name {
                font-size: 23px;
                font-weight: 700;
            }

            .status {
                .slika {
                    display: none;

                    @include media-breakpoint-up(lg) {
                        display: inline-block;
                        margin-left: 10px;
                        width: 20px;
                        height: 20px;
                        background: url(../img/icon-green-checkmark.svg) no-repeat;
                    }
                }

                .rejected {
                    display: none;

                    @include media-breakpoint-up(lg) {
                        display: inline-block;
                        margin-left: 10px;
                        width: 20px;
                        height: 20px;
                        background: url(../img/icon-red-checkmark.svg) no-repeat;
                    }
                }

                .waiting {
                    display: none;

                    @include media-breakpoint-up(lg) {
                        display: inline-block;
                        margin-left: 10px;
                        width: 20px;
                        height: 20px;
                        background: url(../img/icon-green-checkmark-outline.svg) no-repeat;
                    }
                }
            }
        }

        .card-wait {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 20px 0 20px;
            column-gap: 15px;

            @include media-breakpoint-up(lg) {
                flex-direction: row;
                align-items: center;
                padding: 20px 20px 0 20px;
                column-gap: 15px;
            }

            .avatar {
                position: relative;
                gap: 0;

                .avatar-checkmark {
                    position: absolute;
                    bottom: -5px;
                    height: 50px;
                    width: 50px;

                    @include media-breakpoint-up(lg) {
                        display: none;
                    }
                }

                .img-avatar {
                    object-fit: cover;
                    border-radius: 50%;
                    width: 200px;
                    height: 200px;
                }
            }

            .data {
                margin-top: 20px;
                margin-bottom: 10px;

                @include media-breakpoint-up(lg) {
                    margin-top: 0;
                    margin-bottom: 0;
                }

                .details {
                    color: #ababab;
                    text-align: center;

                    @include media-breakpoint-up(lg) {
                        text-align: left;
                    }
                }
            }

            .qr-code {
                margin-left: unset;

                @include media-breakpoint-up(lg) {
                    margin-left: auto;
                }
            }
        }
    }
    /*.profile.card-wait {
        display: flex;
        flex-direction: row;
        justify-content: start;
        gap: 60px;
        padding-left: 40px;
        flex-grow: 2;

        @media (max-width: 600px) {
            flex-direction: column;
        }
    }*/
    //Timeline

    .timeline-wrap {
        padding: 0 20px 0 20px;
        margin-top: 30px;

        @include media-breakpoint-up(lg) {
            padding: 0 90px 0 90px;
        }

        .title {
            font-size: 18px;
            color: $green;
        }

        ul.timeline {

            .block-wrap {
                display: flex;
                column-gap: 10px;

                .action {
                    max-width: 200px;
                    font-weight: 700;

                    &.red {
                        color: $red;
                    }

                    &.green {
                        color: $green;
                    }
                }

                .date {
                    font-size: 13px;
                    color: #7E7E7E;
                    margin-left: auto;
                }
            }

            .comment {
                position: relative;
                border-radius: 10px;
                margin-top: 15px;
                padding: 10px 20px 10px 20px;
                background-color: #F2EEF1;

                &:before {
                    position: absolute;
                    top: -11px;
                    content: "";
                    display: inline-block;
                    width: 20px;
                    height: 11px;
                    margin-right: 10px;
                    background: url(../img/icon-arrow-up-comment.svg) no-repeat;
                }
            }
        }

        ul.timeline {
            list-style-type: none;
            position: relative;
            margin-left: -20px;
        }

        ul.timeline:before {
            content: ' ';
            background: #E5E2E2;
            display: inline-block;
            position: absolute;
            top: -10px;
            left: 30px;
            width: 1px;
            height: 100%;
            z-index: 400;
        }

        ul.timeline > li {
            margin: 20px 0;
            padding-left: 20px;
        }

        ul.timeline > li:before {
            content: ' ';
            //background: #AAAAAA;
            display: inline-block;
            position: absolute;
            border-radius: 50%;
            border: 5px solid #AAAAAA;
            left: 25px;
            width: 10px;
            height: 10px;
            z-index: 400;
            margin-top: 5px;
        }

        ul.timeline > li.active:before {
            border-color: $green;
        }

        .timeline-message {
            margin-top: 30px;

            textarea.form-control {
                border-radius: 15px;
                border: 2px solid $blue !important;
                margin: 10px 0;
                padding: 15px !important;
            }
        }
    }
}

// Tile - kupon
.tile-kupon {
    position: relative;
    display: flex;
    column-gap: 10px;
    margin: 10px 0 20px 0;
    padding: 10px 30px 10px 10px;
    border: 1px solid #E5E2E2;
    border-radius: 15px;
    background: rgb(239,234,234);
    background: linear-gradient(90deg, rgba(239,234,234,1) 0%, rgba(218,223,233,1) 100%);

    @include media-breakpoint-up(xl) {
        padding: 25px;
    }

    .kupon-image-wrap {
        display: flex;
        align-items: center;
        flex-basis: 20%;

        @include media-breakpoint-up(xl) {
            position: absolute;
            top: -21px;
            right: 5%;
        }

        .kupon-image {
            max-width: 100px;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-up(xl) {
            }
        }
    }

    .kupon-content-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-basis: 80%;

        @include media-breakpoint-up(xl) {
            flex-direction: row;
            justify-content: flex-start;
            flex-basis: 100%;
            column-gap: 15px;
        }

        @include media-breakpoint-up(xxl) {
            column-gap: 30px;
        }

        .kupon-title {
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;

            @include media-breakpoint-up(xl) {
                flex-basis: 25%;
                font-size: 18px;
                line-height: 24px;
            }
        }

        .kupon-text {
            @include media-breakpoint-up(xl) {
                flex-basis: 25%;
            }
        }

        .kupon-button {

            @include media-breakpoint-up(xl) {
                display: block;
                flex-basis: 25%;
                text-align: center;
            }
        }
    }

    .kupon-close {
        position: absolute;
        top: 5px;
        right: 5px;
    }
}


// Tile alert
.tile-alert {
    padding: 0 25px 0 25px;
    margin: -10px 0 10px 0;

    div {
        font-size: 14px;
        color: $red;
        font-weight: bold;
        display: inline-block;
        background-color: #f7d9d6;
        padding: 5px 15px 5px 15px;
        border: 1px solid #dfa8b5;
        border-radius: 15px;

        .bi-exclamation-circle::before {
            font-size: 16px;
            font-weight: bold !important;
            margin-right: 7px;
        }
    }
}


// Tile - kartica naro?nina

.kartica-narocnina-wrap {


    @include media-breakpoint-down(md) {
        margin-top: -50px;
        padding-left: 0;
        padding-right: 0;

        section {
            .row {
                margin-left: 0;
                margin-right: 0;

                .col-lg-6 {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}

.tile.kartica-narocnina {
    padding: 0;
    border-radius: 0;
    border: none;
}

.kartica-narocnina {

    .header-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        padding: 100px 25px 140px 25px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;

        @include media-breakpoint-up(md) {
            padding: 25px 25px 140px 25px;
        }

        .status-icon {
            font-size: 45px;
        }

        .status-title {
            font-size: 26px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 10px;
        }

        .status-btn {
            display: flex;
            flex-direction: column;
            row-gap: 5px;

            .btn-primary {
                font-size: 15px;
                font-weight: 700;
                color: $body-color;
                background-color: #fff;
                border: none;
                border-radius: 25px;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }

        &.aktivna {
            background-color: #7da849;
        }

        &.neveljavna {
            background-color: $red;
        }
    }

    .profile {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        padding: 100px 25px 50px 25px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;

        .avatar {
            position: absolute;
            top: -100px;

            .img-avatar {
                object-fit: cover;
                border-radius: 50%;
                width: 200px;
                height: 200px;
                border: 5px solid #fff;
            }
        }

        .profile-name {
            font-size: 26px;
            font-weight: 700;
            margin-top: 20px;
        }
    }
}

// Admin tile footer
.admin-contact {
    margin-top:auto;

    .tile {
        margin: 0;
        background-color: #eaeff7;
        border: 1px solid #eaeff7;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

// Urejanje dogodka
.sport-event-tile-dates {
    background-color: #ECE8E8;
    border: 1px solid #E8E8E9;
    border-radius: 20px;
    padding: 15px;
    margin: 20px 0 20px 0;

    .rec-event {
        font-size: 14px;
        text-align: center;
        color: $body-color;
        padding: 5px;
        border: 2px solid $body-color;
        border-radius: 10px;

        &.selected {
            border: 2px solid $orange;
        }
    }
}

.show-overlay {
    position: absolute;
    background-color: rgba(252,246,246, 0.5);
    //backdrop-filter: blur(1px);
    top: 170px;
    bottom: 0;
    left: 0;
    z-index: 10;

    @include media-breakpoint-up(sm) {
        top: 137px;
    }
}
