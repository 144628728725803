.btn-lg {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 20px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

table {
    .btn {
        box-shadow: none;
    }
}

.btn-document {
    display:flex;
    align-items:center;
    
    &:before {
        content: "";
        width: 15px;
        height: 20px;
        margin-right: 10px;
        background: url(../img/icon-document.svg) no-repeat;
    }
}

.btn-delete {
    display: flex;
    align-items: center;

    &:before {
        content: "";
        width: 18px;
        height: 20px;
        margin-right: 10px;
        background: url(../img/icon-delete-white.svg) no-repeat;
    }
}

.btn-light {
    color: $blue;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    &:hover {
        color: $blue;
    }
}

// Form placeholder
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #939393 !important;
}

::-moz-placeholder { /* Firefox 19+ */
    color: #939393 !important;
}

:-ms-input-placeholder { /* IE 10+ */
    color: #939393 !important;
}

:-moz-placeholder { /* Firefox 18- */
    color: #939393 !important;
}

/*select:invalid {
    color: #939393;
}*/

select:valid {
    color: $dark-blue;
}

a.import-recipients:hover {
    cursor: pointer;
}




