body.body-login {
    height: 100%;
    padding: 0;
    margin: 0;
    background: url('../img/bgr-login.svg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
}

body.body-login #fullPage {
    overflow-x: hidden;
}

.login-bg-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1655px;
    margin: 0 auto;
    margin-top: 20px;
    padding-left: 220px;

    @media (min-width: 1500px) {
        margin-top: 80px;
        background-image: url('../img/bgr-login-wrap.svg');
        background-repeat: no-repeat;
        background-position: center top;
        background-attachment: scroll;
        background-origin: content-box;
        min-height: 860px;
    }

    .login-footer-wrap {
        display: flex;
        margin-left: -220px;
        padding: 0 20px 0 20px;

        @media (min-width: 1500px) {
            padding: 0 220px 0 0;
            margin-left: 0;
        }

        .login-footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 13px;
            color: #fff;
            padding: 0 0 20px 0;
            max-width: 350px;
            margin: 40px auto 0 auto;

            @media (min-width: 1500px) {
                flex-direction: row;
                justify-content: space-between;
                flex: 1;
                max-width: 100%;
            }

            .copyright {
                order: 2;
                margin-top: 40px;
                padding: 0 10px 0 10px;

                @media (min-width: 1500px) {
                    order: 1;
                    margin-top: 0;
                    padding: 0;
                }
            }

            .support {
                order: 1;

                @media (min-width: 1500px) {
                    order: 2;
                    text-align: center;
                }

                a {
                    color: #fff;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        vertical-align: bottom;
                        margin-right: 10px;
                        background: url('../img/icon-podpora.svg') no-repeat;
                    }
                }
            }
        }
    }
}

.login-wrap {
    position: relative;
    margin-left: -220px;
    padding: 0 20px 0 20px;

    @media (min-width: 1500px) {
        padding: 115px 0 0 0;
    }

    .login-welcome {
        position: relative;
        font-size: 15px;
        text-align: center;
        color: #fff;
        max-width: 350px;
        margin: 0 auto;
        padding: 0 60px 0 60px;

        @media (min-width: 1500px) {
            position: absolute;
            left: 180px;
            max-width: 350px;
            font-size: 30px;
            text-align: left;
            margin: 0;
            padding: 0;
        }
    }

    .login-form-wrap {
        position:relative;
        display: flex;
        flex-direction: column;
        max-width: 350px;
        background-color: #fff;
        border-radius: 20px;
        margin: 0 auto;
        border: 1px solid #707070;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

        @media (min-width: 1500px) {
            max-width: 500px;
            border-radius: 50px;
        }

        .login-header {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #E5E2E2;
            padding: 25px 30px 10px 30px;

            @media (min-width: 1500px) {
                padding: 25px 50px 10px 50px;
            }

            .logo {
                align-self: center;
            }

            .welcome {
                font-size: 20px;
                margin: 10px 0 10px 0;
                align-self: center;
                text-align:center;

                @media (min-width: 1500px) {
                    font-size: 30px;
                }
            }
        }

        .login-form {
            padding: 25px 30px 30px 30px;

            @media (min-width: 1500px) {
                padding: 45px 50px 50px 50px;
            }

            .btn.btn-primary {
                padding-top:15px;
                padding-bottom:15px;
            }
        }
    }
}

// Mobile images login form
.image-top-right {
    display: block;
    position: absolute;
    top: -83px;
    right: -16px;

    @media (min-width: 1500px) {
        display: none;
    }
}

.image-top-left {
    display: block;
    position: absolute;
    top: -140px;
    left: -58px;
    z-index: -10;

    @media (min-width: 1500px) {
        display: none;
    }
}

.image-bottom-left {
    display: block;
    position: absolute;
    bottom: -62px;
    left: -55px;
    z-index: -10;

    @media (min-width: 1500px) {
        display: none;
    }
}

.image-bottom-right {
    display: block;
    position: absolute;
    bottom: -36px;
    right: -90px;
    z-index: -10;

    @media (min-width: 1500px) {
        display: none;
    }
}

