.modal {
    &.modal-confirm {
        top: 40%;
        .btn-delete {
            color: white;
        }
    }
    .w-600 {
        max-width: 600px;
        width: 600px;
        margin: auto;
    }
    .modal-dialog {
        @include media-breakpoint-down(sm) {
            margin: 25px;
        }
    }

    .btn-close {
        position: absolute;
        width: 46px;
        height: 46px;
        background: url('../img/icon-circle-close.svg') no-repeat;
        padding: 0;
        margin: 0;
        top: -20px;
        right: -20px;
        opacity: 1;
    }

    .modal-header {
        flex-direction: column;
        align-items: flex-start;
        background-color: #F2EEF1;
        padding: 25px;

        @include media-breakpoint-up(lg) {
            padding: 50px;
        }

        .title {
            display: flex;
            align-items: baseline;
            column-gap: 15px;
            font-size: 28px;
            font-weight: 700;
            margin: 0 0 10px 0;

            .edit {
                font-size: 15px;
                font-weight: normal;

                a {
                    display: flex;

                    &:before {
                        content: "";
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                        background: url(../img/icon-edit.svg) no-repeat;
                    }
                }
            }
        }

        .from {
            font-size: 13px;
            color: #9e9ea0;

            span {
                color: initial;
            }
        }
    }

    .modal-body {
        padding: 25px;

        @include media-breakpoint-up(lg) {
            padding: 50px;
        }

        .title {
            font-size: 21px;
            font-weight: 700;
            margin-bottom: 15px;
        }

        .tile {
            margin-bottom: 10px;

            .lb-wrap {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                .phone {
                    a {
                        font-size: 15px;
                        display: flex;
                        align-items: center;

                        &:before {
                            content: "";
                            width: 13px;
                            height: 13px;
                            margin-right: 10px;
                            background: url(../img/icon-phone.svg) no-repeat;
                        }
                    }
                }
            }

            .label {
                font-weight: 700;
            }

            .data {
                font-size: 14px;
            }

            ul.data {
                list-style: none;
                padding: 0;
                margin: 0;
            }

            ul.attachments {
                a {
                    display: flex;
                    align-items: center;

                    &:before {
                        content: "";
                        width: 11px;
                        height: 12px;
                        margin-right: 10px;
                        background: url(../img/icon-blue-attachment-small.svg) no-repeat;
                    }
                }
            }
        }

        .mb-footer {
            margin-top: 40px;
            padding: 20px 0 10px 0;
            border-top: 1px solid #E5E2E2;
        }
    }
}

// Modal Podrobnosti
.modal.details-log {
    .modal-body {

        .title {
            font-size: 30px;
            font-weight: normal;
            margin-bottom: 20px;
        }

        .profile {
            display: flex;
            align-items: center;
            column-gap: 30px;
            flex-wrap:wrap;

            .avatar {
                max-width: 200px;

                img {
                    object-fit: cover;
                    border-radius: 50%;
                    width: 100px;
                    height: 100px;

                    @include media-breakpoint-up(md) {
                        width: 200px;
                        height: 200px;
                    }
                }
            }

            .data {
                .name {
                    font-size: 23px;
                    font-weight: 700;
                }
            }

            .modal-upload-buttons {
                display: flex;
                flex-direction: row;
                justify-content: center;

                .add-btn {
                    margin: 10px 0 10px 0;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        background: url(../img/icon-plus.svg) no-repeat;
                    }

                    .add-img-btn {
                        cursor: pointer;
                    }
                }

                .add-confirm {
                    margin: 10px 0 10px 0;
                    display: flex;
                    align-items: center;
                    width: fit-content;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        background: url(../img/icon-plus.svg) no-repeat;
                    }

                    .upload-accept-btn {
                        width: 37px;
                        height: 37px;
                        background: url(../img/icon-circle-accept.svg) no-repeat;
                    }
                }

                .add-delete {
                    margin: 10px 0 10px 0;
                    display: flex;
                    align-items: center;
                    width: fit-content;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        background: url(../img/icon-plus.svg) no-repeat;
                    }

                    .upload-decline-btn {
                        width: 37px;
                        height: 37px;
                        background: url(../img/icon-circle-decline.svg) no-repeat;
                    }
                }
            }
        }
        //Timeline

        .timeline-wrap {
            padding: 0 20px 0 20px;
            margin-top: 30px;

            @include media-breakpoint-up(lg) {
                padding: 0 90px 0 90px;
            }

            .title {
                font-size: 18px;
                color: $green;
            }

            ul.timeline {

                .block-wrap {
                    display: flex;
                    column-gap: 10px;

                    .action {
                        max-width: 200px;
                        font-weight: 700;

                        &.red {
                            color: $red;
                        }

                        &.green {
                            color: $green;
                        }
                    }

                    .date {
                        font-size: 13px;
                        color: #7E7E7E;
                        margin-left: auto;
                    }
                }

                .comment {
                    position: relative;
                    border-radius: 10px;
                    margin-top: 15px;
                    padding: 10px 20px 10px 20px;
                    background-color: #F2EEF1;

                    &:before {
                        position: absolute;
                        top: -11px;
                        content: "";
                        display: inline-block;
                        width: 20px;
                        height: 11px;
                        margin-right: 10px;
                        background: url(../img/icon-arrow-up-comment.svg) no-repeat;
                    }
                }
            }

            ul.timeline {
                list-style-type: none;
                position: relative;
                margin-left: -20px;
            }

            ul.timeline:before {
                content: ' ';
                background: #E5E2E2;
                display: inline-block;
                position: absolute;
                top: -10px;
                left: 30px;
                width: 1px;
                height: 100%;
                z-index: 400;
            }

            ul.timeline > li {
                margin: 20px 0;
                padding-left: 20px;
            }

            ul.timeline > li:before {
                content: ' ';
                //background: #AAAAAA;
                display: inline-block;
                position: absolute;
                border-radius: 50%;
                border: 5px solid #AAAAAA;
                left: 25px;
                width: 10px;
                height: 10px;
                z-index: 400;
                margin-top: 5px;
            }

            ul.timeline > li.active:before {
                border-color: $green;
            }

            .timeline-message {
                margin-top: 30px;

                textarea.form-control {
                    border-radius: 15px;
                    border: 2px solid $blue !important;
                    margin: 10px 0;
                    padding: 15px !important;
                }
            }
        }
    }
}

// Modal Actions
.modal.actions {
    .modal-dialog {
        max-width: 650px;
    }

    .modal-body {
        padding: 40px;

        .title {
            font-size: 30px;
            font-weight: normal;
        }

        .btn-row {
            justify-content:flex-end;
            padding: 0;
            margin: 30px 0 0 0;
        }
    }
}

// Modal Add Subscription
.modal.add-subscription {

    .modal-body {
        padding: 40px;

        .title {
            font-size: 30px;
            font-weight: normal;
        }

        .tile {
            margin: 0px 0 5px 0;
            padding: 15px;
            background-color: #E4E6ED;
            border-radius: 15px;
            font-size: 14px;

            .name {
                color: $blue;
                font-weight: 700;
            }

            .date {
                text-align: left;

                @include media-breakpoint-up(lg) {
                    text-align: center;
                }
            }

            .remove {
                text-align: right;
            }
        }

        .btn-row {
            justify-content: flex-end;
            padding: 0;
            margin: 30px 0 0 0;
        }
    }
}

// Modal kupon
.modal.modal-kupon {

    .modal-content {
        background: rgb(239,234,234);
        background: linear-gradient(135deg, rgba(239,234,234,1) 0%, rgba(218,223,233,1) 100%);
    }

    .modal-body {
        padding: 55px 25px 25px 25px;

        .kupon-circle {
            position: absolute;
            max-width: 90px;
            top: -55px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        }

        .top {
            width: 60%;
            margin: 0 auto;

            .title {
                text-align: center;
            }

            .text {
                text-align: center;
                font-size: 13px;
                font-weight: 500;
            }
        }

        .code-wrap {
            text-align: center;
            border-radius: 7px;
            background-color: #fff;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            padding: 5px;
            margin: 15px 0 15px 0;
        }

        .disclaimer {
            font-size: 12px;
            color: #9e9ea0;
        }

        .kupon-download {
            margin-top: 10px;

            a {
                font-size: 13px;

                &:before {
                    content: "";
                    display: inline-block;
                    width: 12px;
                    height: 14px;
                    vertical-align: text-top;
                    margin-right: 7px;
                    background: url(../img/icon-sf-download.svg) no-repeat;
                }
            }
        }
    }
}
// Modal Intranet help
#intranet-help-modal {
    .modal-body {
        padding: 25px 25px 50px 25px;

        @include media-breakpoint-up(lg) {
            padding: 50px;
        }

        .carousel-indicators {
            bottom: -50px;
        }

        .carousel-control-prev {
            width: auto;
            left: -25px;

            @include media-breakpoint-up(lg) {
                left: -40px;
            }
        }

        .carousel-control-next {
            width: auto;
            right: -25px;

            @include media-breakpoint-up(lg) {
                right: -40px;
            }
        }
    }
}

.modal-body {
    .attachments-sidebar {
        border: 1px solid #E7E5E5;
        padding: 20px;
        border-radius: 15px;

        ul {
            list-style: none;
            margin: 5px 0 0 0;
            padding: 0;
            font-size: 14px;
        }

        ul li a {
            display: flex;
            align-items: baseline;
            word-break: break-all;

            &:before {
                content: "";
                display: inline-block;
                min-width: 11px;
                height: 12px;
                margin-right: 8px;
                background: url(../img/icon-blue-attachment-small.svg) no-repeat;
            }
        }
    }
}

// Modal selitve
.modal.selitve {
    .modal-dialog {
        max-width: 660px;
    }

    .modal-content {
        text-align: center;

        .check-icon {
            text-align: center;
            margin-bottom: 20px;

            img {
                width: 50px;
            }
        }

        h3 {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 20px;
            font-weight: normal;
        }

        p {
            padding-left: 2rem;
            padding-right: 2rem;

            @include media-breakpoint-up(md) {
                padding-left: 5rem;
                padding-right: 5rem;
            }
        }

        .btn-row {
            justify-content: center;
            margin: 30px 0 0 0;

            .btn-lg {
                padding-left: 4rem;
                padding-right: 4rem;
            }
        }
    }
}
// Management employment type add
.modal .premission-edit {
    .modal-content {
        max-width: 600px;
        h3 {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 20px;
            font-weight: normal;
        }
        .modal-body{
            textarea.form-control {
                border-radius: 15px;
                border: 3px solid $blue !important;
                margin: 10px 0;
                padding: 15px !important;
            }
        }
    }
}